import React from "react"
import Links from "../constants/links"
import SocialLinks from "../constants/socialLinks"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
const Sidebar = ({ isOpen, toggleSidebar }) => {
 return (
  <aside className={`sidebar ${isOpen ? "show-sidebar" : ""}`}>
   <button className="close-btn" onClick={toggleSidebar}>
    <FaTimes aria-label="close menu"/>
   </button>
   <div className="side-container">
    <Links styleClass={`${isOpen ? "sidebar-links" : ""}`} />
    <SocialLinks styleClass={`${isOpen ? "sidebar-icons" : ""}`} />
   </div>

  </aside>
 )
}

export default Sidebar
